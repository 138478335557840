@use 'vars';

.filter-table {
    position: relative;
}

.clear-filters-button {
    display: none;
    position: absolute;
    top: -33px;
    right: 0;
    padding: 0;

    @include vars.themifyModule() {
        color: vars.color('typo-link');
        &:hover {
            color: vars.color('typo-link-hover');
        }
    }

    &_show {
        display: block;
    }
}

.clear-icon {
    @include vars.themifyModule() {
        margin-right: vars.marginStep();
    }
}
