@use 'vars';

.settings {
    display: flex;
}

.personalization {
    @include vars.themifyModule() {
        margin-left: vars.marginStep(3);
    }
}
