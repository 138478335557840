@use 'vars';

.label-btn {
    @include vars.themifyModule() {
        color: vars.color('typo-primary');
    }
}

.icon {
    @include vars.themifyModule() {
        color: vars.color('typo-link');
    }
}
