@use 'vars';

.profile-header {
    display: flex;
}

.avatar {
    @include vars.themifyModule() {
        margin-right: vars.marginStep(2);
    }
}

.info {
    /**/
}

.name {
    @include vars.themifyModule() {
        @include vars.text('font-2-xl-medium');
    }
    height: 1em;
}

.email {
    @include vars.themifyModule() {
        @include vars.text('font-m-regular');
        color: vars.color('typo-link');
        margin-top: vars.marginStep();
        display: inline-block;
    }
}
