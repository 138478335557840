@use 'vars';

.header {
    display: flex;
    justify-content: space-between;
    @include vars.themifyModule() {
        margin-bottom: vars.marginStep(5);
    }
	&__button{
		display: flex;
		align-items: baseline;
	}
}

.title {
	margin-right: 12px;
    @include vars.themifyModule() {
        @include vars.text('font-l-bold');
    }
}

.clear-btn {
    @include vars.themifyModule() {
        @include vars.text('font-s-regular');
    }
}

.filter {
    @include vars.themifyModule() {
        margin-bottom: vars.marginStep(5);
    }
}
