@use 'vars';

.profile {
    &:hover {
        cursor: pointer;
    }
}

.dropdown {
    @include vars.themifyModule() {
        z-index: vars.themed('zIndex', 'dropdown-header');

        :global(.cd-menu) {
            max-width: 240px;
            max-height: 304px;

            :global(.cd-menu-item) {
                padding-left: vars.marginStep();
            }
        }
    }
}

.notifications {
    @include vars.themifyModule() {
        display: flex;
    }
}

.notification-title {
    vertical-align: middle;
}

.notification-badge {
    @include vars.themifyModule() {
        :global(.ant-badge-count) {
            background: vars.color('control-primary-bg-default');
            min-width: 24px;
            height: 24px;
            line-height: 24px;
            border-radius: 24px;
            padding: 0;
        }
        :global(.ant-badge-multiple-words) {
            padding: 0;
        }
    }
    padding-left: 12px;
}

.menu-header {
    &-name {
        @include vars.themifyModule() {
            @include vars.text('font-m-bold');
            padding-top: 10px;
            padding-bottom: 0px;
        }
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &-email {
        @include vars.themifyModule() {
            padding-top: 0px;
            padding-bottom: 5px;
            color: vars.color('typo-secondary');
        }
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.logout {
    @include vars.themifyModule() {
        padding-top: 7px;
    }
}
