@use 'vars';

.title {
    @include vars.themifyModule() {
        @include vars.text('font-m-bold');
        margin: 0 0 vars.marginStep(0.5);
    }
}

.content {
    @include vars.themifyModule() {
        @include vars.text('font-m-regular');
    }
    margin: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0;
}
.tag {
    padding: 6px;
    line-height: 20px;
    display: inline-block;
    vertical-align: middle;
    margin: 0px 8px 8px 0px;
    cursor: pointer;
    @include vars.themifyModule() {
        background-color: vars.color('control-input-bg-default');
        border-radius: vars.themed('border-radius', 'default');
        &:hover {
            background-color: vars.color('tags-hover-bg');
            color: vars.color('bg-default');
        }
    }
}

.show-more-btn {
    @include vars.themifyModule() {
        margin-top: vars.marginStep();
    }
}
