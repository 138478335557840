$spaceMounts: (4, 8, 12, 16, 24, 32, 36, 40);
$sides: (top, bottom, left, right);

// flex
.d-flex {
    display: flex;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-start {
    justify-content: start;
}

.justify-content-end {
    justify-content: end;
}

.justify-content-between {
    justify-content: space-between;
}
.mt-0{
	margin-top: 0!important;
}
// spacers
@each $space in $spaceMounts {
    $i: index($spaceMounts, $space);
    @each $side in $sides {
        .m#{str-slice($side, 0, 1)}-#{$i} {
            margin-#{$side}: #{$space}px !important;
        }

        .p#{str-slice($side, 0, 1)}-#{$i} {
            padding-#{$side}: #{$space}px !important;
        }
    }
}

//react-split-panel

.Resizer {
    background: #000;
    opacity: 0.2;
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
}

.Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
}

.Resizer.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
}

.Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
}

.Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.disabled {
    cursor: not-allowed;
}
.Resizer.disabled:hover {
    border-color: transparent;
}

// tooltip для разделителя
.tooltip {
    display: none;
    position: fixed;
    color: #fff;
    z-index: 9999;
    padding: 6px 8px;
    margin-left: 25px;
    text-align: left;
    text-decoration: none;
    word-wrap: break-word;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum';
}
.tooltip::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: calc(50% - 6px);
    left: -5px;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 6px solid rgba(0, 0, 0, 0.5);
}

.position-relative{
	position: relative;
}
