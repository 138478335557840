@use 'vars';
@use 'fonts';
@use 'cat-design-imports';
@use '@nkc-frontend/cat-design/dist/styles/reset';
@use 'ant-common';
@use 'react-mde';
@use 'utilities';
@use 'ant-custom';

@import '~@fortawesome/fontawesome-free/css/all.css';

html {
    min-height: 100%;
    height: 100%;
}

body {
    height: 100%;
    margin: 0;
    font-family: 'PT Root UI';
    overflow-x: hidden;
}

#root {
    height: 100%;
}
