@use 'vars';

.search-layout {
    height: 100%;
}

.search-bar-container {
    @include vars.themifyModule() {
        flex-basis: #{vars.themed('size', 'full-content-max-width') *
            vars.themed('size', 'main-content-portion')};
        height: vars.themed('size', 'search-input-height');
    }
}

.header-logo-searchbar {
    @include vars.themifyModule() {
        $main-content-width: #{vars.themed('size', 'full-content-max-width') *
            vars.themed('size', 'main-content-portion')};
        width: calc(
            #{$main-content-width} + (
                    (
                            (
                                    100% + #{vars.themed(
                                            'padding',
                                            'header-left'
                                        )} + #{vars.themed(
                                            'padding',
                                            'header-right'
                                        )}
                                ) - #{vars.themed(
                                    'size',
                                    'full-content-max-width'
                                )}
                        ) / 2
                ) - #{vars.themed('padding', 'header-left')}
        );

        @media (max-width: #{vars.themed('size', 'full-content-max-width') + vars.themed('padding', 'full-content-min') * 2}) {
            width: calc(
                (100vw - #{vars.themed('padding', 'full-content-min') * 2}) * #{vars.themed(
                        'size',
                        'main-content-portion'
                    )}
            );
        }
    }
}

.children {
    @include vars.themifyModule() {
        @include vars.pageLayoutContentSizes(
            vars.themed('size', 'header-search-height'),
            vars.themed('size', 'footer-height'),
            vars.themed('size', 'full-content-max-width'),
            vars.themed('padding', 'full-content-min'),
            vars.marginStep(4),
            vars.marginStep(10)
        );
    }
}

.footer {
    @include vars.themifyModule() {
        margin-top: vars.marginStep(10);
    }
}
