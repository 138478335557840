@use 'vars';

header.header {
    position: relative;
    @include vars.themifyModule() {
        padding-top: vars.marginStep(2);
        padding-right: vars.themed('padding', 'header-right');
        padding-left: vars.themed('padding', 'header-left');
        height: vars.themed('size', 'header-search-height');
    }
}

.content {
    display: flex;
}

.logo-search-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo-wrapper {
    @include vars.themifyModule() {
        margin-right: vars.marginStep(6);
    }
}

.logo {
    @include vars.themifyModule() {
        height: vars.themed('size', 'logo-height');
        width: vars.themed('size', 'logo-width');
    }
}

.search-bar {
    @include vars.themifyModule() {
        margin-bottom: vars.marginStep(2);
    }
}

.menus {
    margin-left: auto;
    display: flex;
    align-items: center;
}

.profile {
    &:hover {
        cursor: pointer;
    }
}

.navigation {
    @include vars.themifyModule() {
        margin-right: vars.marginStep(3);
    }
}
