@use 'vars';

.document-additional-properties {
    @include vars.themifyModule() {
        background-color: vars.color('control-input-bg-default');
        padding: 24px 24px 24px 30px + vars.marginStep(2);
        border-radius: vars.themed('border-radius', 'default');
    }
}

.property-name {
    @include vars.themifyModule() {
        @include vars.text('font-m-bold');
    }
}
