@use 'vars';

.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    @include vars.themifyModule() {
        z-index: vars.themed('zIndex', 'loading-bar');
    }
}

.bar {
    height: 2px;
    @include vars.themifyModule() {
        background: vars.color('brand-primary');
    }
}
