@use 'vars';

.modal-body {
    @include vars.themifyModule() {
        @include vars.text('font-2-xl-medium');
        margin-top: vars.marginStep(5);
    }
}

.error-title:not(:last-child) {
    @include vars.themifyModule() {
        margin-bottom: vars.marginStep();
    }
}

.controls {
    @include vars.themifyModule() {
        margin-top: vars.marginStep(4);
    }
    display: flex;
    justify-content: space-around;
}
