@use 'sass:map';
@use 'vars';

.main {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.content {
    height: 45%;
    display: flex;
    flex-direction: column;
}

.logo {
    display: flex;
    justify-content: space-around;
    margin-top: auto;
    @include vars.themifyModule() {
        margin-bottom: vars.marginStep(7);
    }
}

.search {
    display: flex;
    justify-content: space-around;
}

.search-panel {
    /**/
    @include vars.themifyModule() {
        width: vars.themed('size', 'main-search-panel-width');
    }
}

.background {
    height: 55%;
    @include vars.themifyModule() {
        background-image: vars.themed('background', 'main-page');
        background-size: cover;
        background-repeat: no-repeat;
    }
}
