@use 'vars';

.main-layout {
    height: 100%;
}

.children {
    @include vars.themifyModule() {
        height: calc(100% - #{vars.themed('size', 'header-height')});
    }
}

.footer {
    @include vars.themifyModule() {
        margin-top: -#{vars.themed('size', 'footer-height')};
    }
}
