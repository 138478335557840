@use 'vars';

.card {
    display: flex;
    @include vars.themifyModule() {
        padding: vars.marginStep(2);
    }

    &_unread {
        @include vars.themifyModule() {
            background: vars.color('control-additional-hover');
        }

        .indicator {
            @include vars.themifyModule() {
                background: vars.color('control-primary-bg-default');
            }
        }
    }
}

.date {
    display: flex;
    @include vars.themifyModule() {
        padding-right: vars.marginStep();
        color: vars.color('typo-secondary');
        @include vars.text('font-m-regular');
    }
}

.indicator {
    margin-left: auto;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    //(line-height заголовка / 2) - (высота индикатора / 2)
    margin-top: 6px;
    @include vars.themifyModule() {
        background: vars.color('control-bg-default');
    }
}

.title {
    @include vars.themifyModule() {
        color: vars.color('typo-primary');
        @include vars.text('font-m-bold');
    }
}

.content {
    @include vars.themifyModule() {
        @include vars.text('font-s-light');
    }
}

.truncate {
    //line-height шрифта * 6 строк + 10px паддинга
    //!!! поменять при изменении шрифта в .mde-preview-content !!!
    max-height: 118px;
    overflow: hidden;

    &_open {
        max-height: none;
    }
}
