@use 'vars';
@import '@nkc-frontend/cat-design/dist/components/Button/Button';
@import '@nkc-frontend/cat-design/dist/components/ButtonGroup/ButtonGroup';
@import '@nkc-frontend/cat-design/dist/components/Icon/Icon';
@import '@nkc-frontend/cat-design/dist/components/Dropdown/Dropdown';
@import '@nkc-frontend/cat-design/dist/components/Menu/Menu/Menu';
@import '@nkc-frontend/cat-design/dist/components/Menu/MenuItem/MenuItem';
@import '@nkc-frontend/cat-design/dist/components/Menu/MenuDivider/MenuDivider';
@import '@nkc-frontend/cat-design/dist/components/Avatar/Avatar';
@import '@nkc-frontend/cat-design/dist/components/TextInput/TextInput';
@import '@nkc-frontend/cat-design/dist/components/SearchInput/SearchInput';
@import '@nkc-frontend/cat-design/dist/components/Footer/Footer';
@import '@nkc-frontend/cat-design/dist/components/Tag/Tag';
@import '@nkc-frontend/cat-design/dist/components/Suggester/Suggester';
@import '@nkc-frontend/cat-design/dist/components/Tabs/Tabs';
@import '@nkc-frontend/cat-design/dist/components/Switch/Switch';
@import '@nkc-frontend/cat-design/dist/components/Sidepage/Sidepage';
@import '@nkc-frontend/cat-design/dist/components/List/List';
@import '@nkc-frontend/cat-design/dist/components/Checkbox/Checkbox';
@import '@nkc-frontend/cat-design/dist/components/Radiobutton/Radiobutton';
@import '@nkc-frontend/cat-design/dist/components/Radiogroup/Radiogroup';
@import '@nkc-frontend/cat-design/dist/components/NumberInput/NumberInput';
@import '@nkc-frontend/cat-design/dist/components/selects/InlineSelect/InlineSelect';
@import '@nkc-frontend/cat-design/dist/components/selects/SidepageSelect/SidepageSelect';
@import '@nkc-frontend/cat-design/dist/components/selects/PeriodSelect/PeriodSelect';
@import '@nkc-frontend/cat-design/dist/components/DateInput/DateInput';
@import '@nkc-frontend/cat-design/dist/components/Pagination/Pagination';
@import '@nkc-frontend/cat-design/dist/components/Truncate/Truncate';
@import '@nkc-frontend/cat-design/dist/components/CollapseToggle/CollapseToggle';
@import '@nkc-frontend/cat-design/dist/components/NaumenLogo/NaumenLogo';




.ant-table-cell{
	a {
		color: #1890ff;
		text-decoration: none;
		background-color: transparent;
		outline: none;
		cursor: pointer;
		-webkit-transition: color 0.3s;
		transition: color 0.3s;
		&:hover {
			color: #40a9ff;
		}
		&:active {
			color: #096dd9;
		}
	}

}
