@use 'vars';

.ant-picker .anticon-close-circle,
.ant-select .anticon-close-circle,
.ant-input-clear-icon {
    @include vars.themify() {
        color: vars.color('icon-clear');
        font-size: 14px;
    }

    &:hover {
        @include vars.themify() {
            color: vars.color('icon-clear');
        }
    }
}

.ant-modal-root {
    .ant-btn-primary {
        @include vars.themify() {
            background: vars.color('typo-link');
            color: vars.color('table-filter-btn-color');

            &:hover {
                background: vars.color('typo-link-hover');
            }
        }
    }
}

.ant-table-filter-dropdown {
    &-btns {
        .ant-btn-primary {
            width: 70px;

            @include vars.themify() {
                background: vars.color('typo-link');
                color: vars.color('table-filter-btn-color');

                &:hover {
                    background: vars.color('typo-link-hover');
                }
            }
        }

        .ant-btn-link {
            @include vars.themify() {
                border-color: #d9d9d9;
                &:hover {
                    border-color: vars.color('typo-link');
                }
            }
        }
    }
}

.ant-table-wrapper .ant-dropdown-open .anticon {
    @include vars.themify() {
        color: vars.color('typo-link');
    }
}
