@use 'vars';

.dropdown-filter {
    @include vars.themifyModule() {
        padding: vars.marginStep();
    }
}

@include vars.adminStyles() {
    .input {
        display: block;
        width: 188px;
    }
}

.input {
    display: block;
    width: 188px;
    @include vars.themifyModule() {
        margin-bottom: vars.marginStep();
    }
}

.search-button {
    width: 90px;
    border: none;
    outline: none;
    @include vars.themifyModule() {
        margin-right: vars.marginStep();
        background: vars.color('typo-link');
        color: vars.color('table-filter-btn-color');

        &:hover {
            background: vars.color('typo-link-hover');
        }
    }
}

.search-icon {
    @include vars.themifyModule() {
        margin-right: vars.marginStep();
    }
}

.reset-button {
    width: 90px;
}
