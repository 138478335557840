@use 'vars';

.navigation {
    &__icon {
        @include vars.themifyModule() {
            color: vars.color('control-primary-bg-default');
        }

        &:hover {
            cursor: pointer;
        }
    }
}

.dropdown {
    @include vars.themifyModule() {
        z-index: vars.themed('zIndex', 'dropdown-header');
    }
}

.navigation-icon {
    @include vars.themifyModule() {
        color: vars.color('control-bg-default');
        margin-right: vars.marginStep(1);
    }
}
