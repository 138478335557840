@use 'vars';

.title {
    @include vars.themifyModule() {
        color: vars.color('typo-secondary');
        @include vars.text('font-header-title');
    }
    text-transform: uppercase;
    letter-spacing: 0.05em;
}
