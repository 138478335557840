@use 'vars';

$date-column-width: 15%;

.read-all-btn {
    @include vars.themifyModule() {
        @include vars.text('font-m-regular');
    }
}

.table-header {
    display: flex;
    @include vars.themifyModule() {
        margin-top: vars.marginStep(3);
        color: vars.color('typo-primary');
        @include vars.text('font-m-medium');
        border-bottom: 1px solid vars.color('control-border');
        padding: vars.marginStep(2);
        padding-top: 0;
    }
}

.header-date {
    width: $date-column-width;
}

.notification {
    @include vars.themifyModule() {
        border-bottom: 1px solid vars.color('control-border');
    }
}

.notification-date {
    width: $date-column-width;
}

.notification-main {
    width: 50%;
    [class*='-btn'] {
        button {
            font-weight: normal;
        }
    }
}

.pagination {
    @include vars.themifyModule() {
        margin-top: vars.marginStep(4);
    }
}
