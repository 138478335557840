//ЗДЕСЬ СОБРАНЫ СТИЛИ, КОТОРЫЕ ВСТРЕЧАЮТСЯ И В ОСНОВНОМ ПРИЛОЖЕНИИ И В АДМИНКЕ
//ЭТО СДЕЛАНО, ЧТОБЫ НЕ ТАЩИТЬ В ОСНОВНОЙ ФРОНТ КУЧУ НЕНУЖНОГО CSS

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-badge {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum';
    position: relative;
    display: inline-block;
    line-height: 1;
}
.ant-badge-count {
    z-index: auto;
    min-width: 20px;
    height: 20px;
    padding: 0 6px;
    color: #fff;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    text-align: center;
    background: #ff4d4f;
    border-radius: 10px;
    -webkit-box-shadow: 0 0 0 1px #fff;
    box-shadow: 0 0 0 1px #fff;
}
.ant-badge-count a,
.ant-badge-count a:hover {
    color: #fff;
}
.ant-badge-count-sm {
    min-width: 14px;
    height: 14px;
    padding: 0;
    font-size: 12px;
    line-height: 14px;
    border-radius: 7px;
}
.ant-badge-multiple-words {
    padding: 0 8px;
}
.ant-badge-dot {
    z-index: auto;
    width: 6px;
    min-width: 6px;
    height: 6px;
    background: #ff4d4f;
    border-radius: 100%;
    -webkit-box-shadow: 0 0 0 1px #fff;
    box-shadow: 0 0 0 1px #fff;
}
.ant-badge-count,
.ant-badge-dot,
.ant-badge .ant-scroll-number-custom-component {
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
}
.ant-badge-status {
    line-height: inherit;
    vertical-align: baseline;
}
.ant-badge-status-dot {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 6px;
    height: 6px;
    vertical-align: middle;
    border-radius: 50%;
}
.ant-badge-status-success {
    background-color: #52c41a;
}
.ant-badge-status-processing {
    position: relative;
    background-color: #1890ff;
}
.ant-badge-status-processing::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #1890ff;
    border-radius: 50%;
    -webkit-animation: antStatusProcessing 1.2s infinite ease-in-out;
    animation: antStatusProcessing 1.2s infinite ease-in-out;
    content: '';
}
.ant-badge-status-default {
    background-color: #d9d9d9;
}
.ant-badge-status-error {
    background-color: #ff4d4f;
}
.ant-badge-status-warning {
    background-color: #faad14;
}
.ant-badge-status-pink {
    background: #eb2f96;
}
.ant-badge-status-magenta {
    background: #eb2f96;
}
.ant-badge-status-red {
    background: #f5222d;
}
.ant-badge-status-volcano {
    background: #fa541c;
}
.ant-badge-status-orange {
    background: #fa8c16;
}
.ant-badge-status-yellow {
    background: #fadb14;
}
.ant-badge-status-gold {
    background: #faad14;
}
.ant-badge-status-cyan {
    background: #13c2c2;
}
.ant-badge-status-lime {
    background: #a0d911;
}
.ant-badge-status-green {
    background: #52c41a;
}
.ant-badge-status-blue {
    background: #1890ff;
}
.ant-badge-status-geekblue {
    background: #2f54eb;
}
.ant-badge-status-purple {
    background: #722ed1;
}
.ant-badge-status-text {
    margin-left: 8px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
}
.ant-badge-zoom-appear,
.ant-badge-zoom-enter {
    -webkit-animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.ant-badge-zoom-leave {
    -webkit-animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
    animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.ant-badge-not-a-wrapper .ant-badge-zoom-appear,
.ant-badge-not-a-wrapper .ant-badge-zoom-enter {
    -webkit-animation: antNoWrapperZoomBadgeIn 0.3s
        cubic-bezier(0.12, 0.4, 0.29, 1.46);
    animation: antNoWrapperZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
}
.ant-badge-not-a-wrapper .ant-badge-zoom-leave {
    -webkit-animation: antNoWrapperZoomBadgeOut 0.3s
        cubic-bezier(0.71, -0.46, 0.88, 0.6);
    animation: antNoWrapperZoomBadgeOut 0.3s
        cubic-bezier(0.71, -0.46, 0.88, 0.6);
}
.ant-badge-not-a-wrapper:not(.ant-badge-status) {
    vertical-align: middle;
}
.ant-badge-not-a-wrapper .ant-scroll-number-custom-component {
    -webkit-transform: none;
    transform: none;
}
.ant-badge-not-a-wrapper .ant-scroll-number-custom-component,
.ant-badge-not-a-wrapper .ant-scroll-number {
    position: relative;
    top: auto;
    display: block;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
}
.ant-badge-not-a-wrapper .ant-badge-count {
    -webkit-transform: none;
    transform: none;
}

.ant-scroll-number {
    overflow: hidden;
}
.ant-scroll-number-only {
    position: relative;
    display: inline-block;
    height: 20px;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
}
.ant-scroll-number-only > p.ant-scroll-number-only-unit {
    height: 20px;
    margin: 0;
    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
}
.ant-scroll-number-symbol {
    vertical-align: top;
}

.ant-slide-up-enter,
.ant-slide-up-appear {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}
.ant-slide-up-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}
.ant-slide-up-enter.ant-slide-up-enter-active,
.ant-slide-up-appear.ant-slide-up-appear-active {
    -webkit-animation-name: antSlideUpIn;
    animation-name: antSlideUpIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
}
.ant-slide-up-leave.ant-slide-up-leave-active {
    -webkit-animation-name: antSlideUpOut;
    animation-name: antSlideUpOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
}
.ant-slide-up-enter,
.ant-slide-up-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-up-leave {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-down-enter,
.ant-slide-down-appear {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}
.ant-slide-down-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}
.ant-slide-down-enter.ant-slide-down-enter-active,
.ant-slide-down-appear.ant-slide-down-appear-active {
    -webkit-animation-name: antSlideDownIn;
    animation-name: antSlideDownIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
}
.ant-slide-down-leave.ant-slide-down-leave-active {
    -webkit-animation-name: antSlideDownOut;
    animation-name: antSlideDownOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
}
.ant-slide-down-enter,
.ant-slide-down-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-down-leave {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-left-enter,
.ant-slide-left-appear {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}
.ant-slide-left-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}
.ant-slide-left-enter.ant-slide-left-enter-active,
.ant-slide-left-appear.ant-slide-left-appear-active {
    -webkit-animation-name: antSlideLeftIn;
    animation-name: antSlideLeftIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
}
.ant-slide-left-leave.ant-slide-left-leave-active {
    -webkit-animation-name: antSlideLeftOut;
    animation-name: antSlideLeftOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
}
.ant-slide-left-enter,
.ant-slide-left-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-left-leave {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-right-enter,
.ant-slide-right-appear {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}
.ant-slide-right-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}
.ant-slide-right-enter.ant-slide-right-enter-active,
.ant-slide-right-appear.ant-slide-right-appear-active {
    -webkit-animation-name: antSlideRightIn;
    animation-name: antSlideRightIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
}
.ant-slide-right-leave.ant-slide-right-leave-active {
    -webkit-animation-name: antSlideRightOut;
    animation-name: antSlideRightOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
}
.ant-slide-right-enter,
.ant-slide-right-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-right-leave {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

/* ----------------------- ANIMATION KEYFRAMES ----------------------*/
@-webkit-keyframes antSlideUpIn {
    0% {
        -webkit-transform: scaleY(0.8);
        transform: scaleY(0.8);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        opacity: 0;
    }
    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        opacity: 1;
    }
}
@keyframes antSlideUpIn {
    0% {
        -webkit-transform: scaleY(0.8);
        transform: scaleY(0.8);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        opacity: 0;
    }
    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        opacity: 1;
    }
}
@-webkit-keyframes antSlideUpOut {
    0% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        opacity: 1;
    }
    100% {
        -webkit-transform: scaleY(0.8);
        transform: scaleY(0.8);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        opacity: 0;
    }
}
@keyframes antSlideUpOut {
    0% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        opacity: 1;
    }
    100% {
        -webkit-transform: scaleY(0.8);
        transform: scaleY(0.8);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        opacity: 0;
    }
}
@-webkit-keyframes antSlideDownIn {
    0% {
        -webkit-transform: scaleY(0.8);
        transform: scaleY(0.8);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
        opacity: 0;
    }
    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
        opacity: 1;
    }
}
@keyframes antSlideDownIn {
    0% {
        -webkit-transform: scaleY(0.8);
        transform: scaleY(0.8);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
        opacity: 0;
    }
    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
        opacity: 1;
    }
}
@-webkit-keyframes antSlideDownOut {
    0% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
        opacity: 1;
    }
    100% {
        -webkit-transform: scaleY(0.8);
        transform: scaleY(0.8);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
        opacity: 0;
    }
}
@keyframes antSlideDownOut {
    0% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
        opacity: 1;
    }
    100% {
        -webkit-transform: scaleY(0.8);
        transform: scaleY(0.8);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
        opacity: 0;
    }
}
@-webkit-keyframes antSlideLeftIn {
    0% {
        -webkit-transform: scaleX(0.8);
        transform: scaleX(0.8);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        opacity: 0;
    }
    100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        opacity: 1;
    }
}
@keyframes antSlideLeftIn {
    0% {
        -webkit-transform: scaleX(0.8);
        transform: scaleX(0.8);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        opacity: 0;
    }
    100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        opacity: 1;
    }
}
@-webkit-keyframes antSlideLeftOut {
    0% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        opacity: 1;
    }
    100% {
        -webkit-transform: scaleX(0.8);
        transform: scaleX(0.8);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        opacity: 0;
    }
}
@keyframes antSlideLeftOut {
    0% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        opacity: 1;
    }
    100% {
        -webkit-transform: scaleX(0.8);
        transform: scaleX(0.8);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        opacity: 0;
    }
}
@-webkit-keyframes antSlideRightIn {
    0% {
        -webkit-transform: scaleX(0.8);
        transform: scaleX(0.8);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 0;
    }
    100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 1;
    }
}
@keyframes antSlideRightIn {
    0% {
        -webkit-transform: scaleX(0.8);
        transform: scaleX(0.8);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 0;
    }
    100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 1;
    }
}
@-webkit-keyframes antSlideRightOut {
    0% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 1;
    }
    100% {
        -webkit-transform: scaleX(0.8);
        transform: scaleX(0.8);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 0;
    }
}
@keyframes antSlideRightOut {
    0% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 1;
    }
    100% {
        -webkit-transform: scaleX(0.8);
        transform: scaleX(0.8);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 0;
    }
}

@-webkit-keyframes antStatusProcessing {
    0% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
        opacity: 0.5;
    }
    100% {
        -webkit-transform: scale(2.4);
        transform: scale(2.4);
        opacity: 0;
    }
}
@keyframes antStatusProcessing {
    0% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
        opacity: 0.5;
    }
    100% {
        -webkit-transform: scale(2.4);
        transform: scale(2.4);
        opacity: 0;
    }
}
@-webkit-keyframes antZoomBadgeIn {
    0% {
        -webkit-transform: scale(0) translate(50%, -50%);
        transform: scale(0) translate(50%, -50%);
        opacity: 0;
    }
    100% {
        -webkit-transform: scale(1) translate(50%, -50%);
        transform: scale(1) translate(50%, -50%);
    }
}
@keyframes antZoomBadgeIn {
    0% {
        -webkit-transform: scale(0) translate(50%, -50%);
        transform: scale(0) translate(50%, -50%);
        opacity: 0;
    }
    100% {
        -webkit-transform: scale(1) translate(50%, -50%);
        transform: scale(1) translate(50%, -50%);
    }
}
@-webkit-keyframes antZoomBadgeOut {
    0% {
        -webkit-transform: scale(1) translate(50%, -50%);
        transform: scale(1) translate(50%, -50%);
    }
    100% {
        -webkit-transform: scale(0) translate(50%, -50%);
        transform: scale(0) translate(50%, -50%);
        opacity: 0;
    }
}
@keyframes antZoomBadgeOut {
    0% {
        -webkit-transform: scale(1) translate(50%, -50%);
        transform: scale(1) translate(50%, -50%);
    }
    100% {
        -webkit-transform: scale(0) translate(50%, -50%);
        transform: scale(0) translate(50%, -50%);
        opacity: 0;
    }
}
@-webkit-keyframes antNoWrapperZoomBadgeIn {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@keyframes antNoWrapperZoomBadgeIn {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@-webkit-keyframes antNoWrapperZoomBadgeOut {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }
}
@keyframes antNoWrapperZoomBadgeOut {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }
}

@-webkit-keyframes diffZoomIn1 {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@keyframes diffZoomIn1 {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@-webkit-keyframes diffZoomIn2 {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@keyframes diffZoomIn2 {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@-webkit-keyframes diffZoomIn3 {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@keyframes diffZoomIn3 {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@-webkit-keyframes antShowHelpIn {
    0% {
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes antShowHelpIn {
    0% {
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}
@-webkit-keyframes antShowHelpOut {
    to {
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
        opacity: 0;
    }
}
@keyframes antShowHelpOut {
    to {
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
        opacity: 0;
    }
}
