@use 'vars';

.title {
	@include vars.themifyModule() {
		@include vars.text('font-2-xl-medium');
	}
	width: 68%;
	overflow-wrap: break-word
}

.btn-icon {
	@include vars.themifyModule() {
		color: vars.color('control-bg-default');
	}
}

.source-btn {
	@include vars.themifyModule() {
		margin-right: vars.marginStep(3);
	}
}
.back-btn{
	display: flex;
	align-items: center;
	line-height: 1;
	span {
		line-height: 1;
		position: relative;
		top: -1px;
	}
}

.properties {
	display: flex;
}

.properties-right {
	width: 68%;
	margin-right: 152px;
}

.properties-left {
	flex: 1;
}

.additional-properties {
	@include vars.themifyModule() {
		margin-top: vars.marginStep(5);
	}
}

.show-more-btn {
	@include vars.themifyModule() {
		margin-top: vars.marginStep();
	}
}

.container {
	position: relative;
	width: 100vw;
	left: calc((100vw - 1400px) / 2 * -1);

	.wrap {
		max-width: 1400px;
		margin: 0 auto;
		width: 100%;
	}

	article {
		transition: width 0.3s;
		width: 100%;
	}

	&.open {
		article {
			.properties-right {
				width: 100%;
				margin-right: 0;
			}
		}

		.sidebar {
			display: block;
			right: 0;
			box-shadow: 0 4px 12px rgba(45, 38, 38, 0.25);
		}
	}
}

.sidebar {
	background: white;
	position: absolute;
	transition: right 0.3s;
	width: 632px;
	height: calc(100% + 128px);
	right: -900px;
	top: -48px;
	min-height: calc(100vh - 160px);
	display: none;
}

.count {
	margin-left: 4px;
	@include vars.themifyModule() {
		color: vars.color('typo-link');
	}

}

@media (max-width: 1512px) {
	.container {
		position: relative;
		width: 100%;
		left: 0;

		.wrap {
			max-width: 100%;
			margin: 0 auto;
			width: 100%;
		}

		&.open {
			.sidebar {
				right: -55px;

			}
		}
	}
}
