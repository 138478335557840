@use 'vars';

.results {
    display: flex;

    &_no-filters {
        .main {
            @include vars.themifyModule() {
                width: 100%;
            }
        }
    }
}

.main {
    @include vars.themifyModule() {
        width: vars.themed('size', 'main-content-percentage');
        margin-right: vars.marginStep(4);
    }
}

.summary-row {
    @include vars.themifyModule() {
        margin-bottom: vars.marginStep(2);
    }
}

.filters {
    @include vars.themifyModule() {
        width: calc(
            #{100% - vars.themed('size', 'main-content-percentage')} - #{vars.marginStep(
                    4
                )}
        );
    }
}

.snippet {
    @include vars.snippet();
}
