@use 'vars';
//TODO: нужно решить, использовать ли их css или переписать самим
@import 'react-mde/lib/styles/css/react-mde-all.css';

.mde-preview-content {
    font-feature-settings: 'tnum';
    font-variant: tabular-nums;
    font-variant-numeric: tabular-nums;
    list-style: outside none none;
    list-style-type: none;
    overflow-wrap: break-word;
    word-wrap: break-word;

    @include vars.themify() {
        padding: 10px 0 0 0;
        color: vars.color('typo-primary');
        @include vars.text('font-s-light');
    }
    h4 {
        font-weight: 500;
        margin-bottom: 0.5em;
    }

    ul {
        list-style: disc;
    }

    ol {
        list-style: decimal;
    }

    img {
        max-width: 100%;
    }

    a {
        text-decoration: none;
        @include vars.themify() {
            color: vars.color('typo-link');

            &:hover {
                color: vars.color('typo-link-hover');
            }
        }

        &:hover {
            text-decoration: none;
        }
    }

    * {
        color: inherit;
    }
}
