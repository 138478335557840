@use 'vars';

.similarityTable {
    margin-top: 16px;
    tbody {
        tr {
            td:last-of-type {
                span {
                    display: flex;

                    align-items: center;

                    svg {
                        @include vars.themifyModule() {
                            stroke: vars.color('icon-default');
                            &:hover {
                                stroke: vars.color(
                                    'control-primary-bg-default'
                                );
                            }
                        }
                        margin-left: 12px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .selected-row {
        background: #d9e7ff;

        &:hover {
            background: #d9e7ff !important;
            & > td {
                background: transparent !important;
            }
        }
    }
}
