@use 'vars';

.documentData {
    margin-top: 40px;

    &__toggle {
        margin-bottom: 20px;
        cursor: pointer;
        font-size: 16px;
        font-weight: bold;
        line-height: 20px;

        @include vars.themifyModule() {
            color: vars.color('typo-primary');
        }

        &.isOpen {
            i {
                transform: rotate(-180deg);
            }
        }

        i {
            transition: all 0.3s;
            position: relative;
            font-size: 10px;
            display: inline-block;
            margin-left: 8px;
        }
    }

    &__container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: stretch;
    }

    &__block {
        margin-bottom: 24px;
        width: calc(50% - 30px);

        &-title {
            font-weight: 400;
            font-size: 14px;
            line-height: 129%;
            padding-bottom: 12px;
            @include vars.themifyModule() {
                color: vars.color('typo-secondary');
                border-bottom: 1px solid vars.color('control-border');
            }
        }

        &-content {
            padding-top: 12px;
        }
    }
	&__link{
		cursor: pointer;
		@include vars.themifyModule() {
			color: vars.color('typo-link');
			&:hover {
				color: vars.color('typo-link-hover');
			}
		}
	}

	&__link + &__link{
		margin-top: 8px;
	}
}
