@use 'vars';

.tabs {
    @include vars.themifyModule() {
        margin-top: vars.marginStep(3);
    }
}

.children {
    @include vars.themifyModule() {
        margin-top: vars.marginStep(4);
    }
}
