@use "vars";

//Большинство css взято из antd стилей для модалки

.rc-dialog-mask {
    @include vars.themify() {
        background-color: vars.color('bg-modal');
        z-index: vars.themed('zIndex', 'modal');
    }
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
}

.rc-dialog-wrap {
    bottom: 0;
    left: 0;
    outline: 0;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    @include vars.themify() {
        z-index: vars.themed('zIndex', 'modal');
    }
}

.rc-dialog {
    @include vars.themify() {
        color: vars.color('typo-primary');
    }
    font-feature-settings: 'tnum';
    font-variant: tabular-nums;
    margin: 0 auto;
    max-width: calc(100vw - 32px);
    pointer-events: none;
    position: relative;
    top: 100px;
    width: 520px;
}

.rc-dialog-content {
    @include vars.themify() {
        background-color: vars.color('bg-default');
        border-radius: vars.themed('border-radius', 'default');
    }
    background-clip: padding-box;
    border: 0;
    border-radius: 2px;
    pointer-events: auto;
    position: relative;
}

.rc-dialog-body {
    word-wrap: break-word;
    font-size: 14px;
    line-height: 1.5715;
    padding: 24px;
}

.rc-dialog-close {
    outline: none;
    box-shadow: none;
    border: none;
    font-family: inherit;
    display: inline-block;
    text-decoration: none;
    white-space: nowrap;
    text-align: center;
    appearance: none;
    background: none;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 20px;
    right: 20px;
	&:hover{
		cursor: pointer;
	}
}
