@use 'vars';

.filter-date-picker {
    @include vars.themifyModule() {
        padding: vars.marginStep();
    }
}

.picker-block {
    display: flex;
    @include vars.themifyModule() {
        margin-bottom: vars.marginStep();
    }
}

.start-picker {
    flex-grow: 1;
}

.end-picker {
    @include vars.themifyModule() {
        margin-left: vars.marginStep();
    }
    flex-grow: 1;
}

.buttons {
    display: flex;
    align-items: flex-end;
    position: relative;
    width: 100%;
    height: 24px;
}

.reset-button,
.search-button {
    width: 90px;
    height: 24px;
    margin-left: auto;
}

.search-button {
    @include vars.themifyModule() {
        background: vars.color('typo-link');
        color: vars.color('table-filter-btn-color');

        &:hover {
            background: vars.color('typo-link-hover');
        }
    }
}

.reset-button {
    @include vars.themifyModule() {
        margin-left: vars.marginStep();
    }
}

.tag {
    cursor: pointer;
}
