@use 'vars';

header.header {
    @include vars.themifyModule() {
        padding-right: vars.themed('padding', 'header-right');
        padding-left: vars.themed('padding', 'header-left');
    }
    padding-top: 0;
    display: flex;
    align-items: center;
}

.logo-wrapper {
    @include vars.themifyModule() {
        margin-right: vars.marginStep(6);
    }
}

.logo {
    @include vars.themifyModule() {
        height: vars.themed('size', 'logo-height');
        width: vars.themed('size', 'logo-width');
    }
}

.menus {
    margin-left: auto;
    display: flex;
    align-items: center;
}

.navigation {
    @include vars.themifyModule() {
        margin-right: vars.marginStep(3);
    }
}
