@use 'vars';

.page-layout {
    height: 100%;
}

.children {
    @include vars.themifyModule() {
        @include vars.pageLayoutContentSizes(
            vars.themed('size', 'header-height'),
            vars.themed('size', 'footer-height'),
            vars.themed('size', 'full-content-max-width'),
            vars.themed('padding', 'full-content-min'),
            vars.marginStep(6),
            vars.marginStep(10)
        );
    }
}

.footer {
    @include vars.themifyModule() {
        margin-top: vars.marginStep(10);
    }
}
