.modal-about-img{
	background: url("../../../images/about.png") no-repeat;
	background-size: contain;
	width: 100%;
	height: 200px;
}

.modal-about-dialog{
	width: 800px;
}
.footer-without-logo{
	svg{
		display: none!important;
	}
}
