@use 'vars';

.search-summary {
    display: flex;

    @include vars.themifyModule() {
        @include vars.text('font-s-regular');
    }
}

.subject-count {
    @include vars.themifyModule() {
        @include vars.text('font-s-bold');
    }
}

.end-node {
    margin-left: auto;
}
