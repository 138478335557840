@use 'vars';

.spellCheck {
    position: relative;
    @include vars.themifyModule() {
        @include vars.text('font-s-regular');
    }
    width: 100%;
    background: rgba(204, 215, 231, 0.3);
    padding: 30px 0;
    display: flex;
    &__container {
        @include vars.themifyModule() {
            flex-basis: #{vars.themed('size', 'full-content-max-width') *
                vars.themed('size', 'main-content-portion')};
            margin-left: 255px;
            color: #141313;
            font-size: 16px;
        }
        a {
            color: #1c69f3;
        }
    }
}
