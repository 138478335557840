@use 'vars';

.results {
	@include vars.themifyModule() {
		margin-bottom: vars.marginStep(8);
	}
}

.load-more-btn {
	width: 100%;
	@include vars.themifyModule() {
		background-color: vars.color('control-input-bg-default');
		&:hover,
		&:focus {
			background-color: vars.color('control-input-bg-hover');
		}
	}
}

.pagination {
	ul {
		width: 100%;
		display: flex;
		justify-content: center;
	}
}
