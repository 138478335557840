@use 'vars';

.container {
	padding: 40px 8px 40px 16px;
	position: relative;
	display: flex;
	flex-direction: column;
	height: 100%;

	& > button {
		position: absolute;
		right: 20px;
		top: 20px;
		cursor: pointer;
	}
}

.title {
	@include vars.themifyModule() {
		@include vars.text('font-2-xl-medium');
	}
	margin-bottom: 22px;
	padding-left: 24px;
}

.openModal {
	margin-left: 26px;
	display: flex;
	align-items: center;
	margin-top: auto;
	cursor: pointer;
	margin-bottom: 15px;
	@include vars.themifyModule() {
		span {
			color: vars.color('control-primary-bg-default');
			margin-left: 8px;
		}
		&:hover {
			span {
				color: #40a9ff;
			}
		}
	}
}

.search {
	padding-left: 24px;
}

.list {
	position: relative;
	height: 100%;
	overflow: auto;
}

.row {
	padding: 24px 0;
	margin-right: 6px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #c4c4c4;

	& > div {
		padding-left: 24px;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		width: 500px;
	}
}

.icon {
	margin-right: 22px;
	cursor: pointer;
	@include vars.themifyModule() {
		stroke: vars.color('icon-default');
		&:hover {
			stroke: vars.color('control-primary-bg-default');
		}
	}
}


.dialog {
	padding: 16px;
}

.modalContent {
	max-height: 545px;
	overflow-x: hidden;
	font-size: 18px;

	span {
		background: #faff02;
	}
}

.ner {
	padding-left: 24px;

	&__title,
	&__text {
		font-size: 14px;
		font-weight: 400;
		line-height: 18px;
	}

	&__title {
		@include vars.themifyModule() {
			color: vars.color('typo-secondary');
			border-bottom: 1px solid vars.color('control-border');
		}
		padding-bottom: 12px;
		margin-bottom: 12px
	}

	&__text {
		@include vars.themifyModule() {
			color: vars.color('typo-primary');
		}
		div + div {
			margin-top: 8px;
		}
	}

}

.ner + .ner {
	margin-top: 28px;
}
