@use 'vars';

.button-icon {
    @include vars.themifyModule() {
        color: vars.color('control-bg-default');
    }
}

.icon {
    cursor: pointer;
}
