@use 'vars';

.document-snippet {
    position: relative;
    @include vars.themifyModule() {
        padding: vars.marginStep(3) vars.marginStep(7) vars.marginStep(3)
            vars.marginStep(3);
    }

    &:hover {
        @include vars.themifyModule() {
            box-shadow: vars.themed('shadow', 'short');
        }
        .document-snippet__like-button {
            display: block;
        }
    }

    &_with_image {
        .document-snippet__annotation {
            line-clamp: 6;
            -webkit-line-clamp: 6;
        }
    }

    &__ {
        &like-button {
            position: absolute;
            display: none;
            @include vars.themifyModule() {
                top: vars.marginStep(3);
                right: vars.marginStep(3);
            }
        }

        &upper-info-line {
            display: flex;
            @include vars.themifyModule() {
                @include vars.text('font-s-regular');
                color: vars.color('typo-secondary');

                & > *:not(:first-child) {
                    margin-left: vars.marginStep(2);
                }
            }
            white-space: nowrap;
        }

        &authors {
            text-overflow: ellipsis;
            overflow: hidden;
            @include vars.themifyModule() {
                color: vars.color('typo-primary');
            }
        }

        &title {
            text-align: left;
            @include vars.themifyModule() {
                @include vars.text('font-l-medium');
                margin: vars.marginStep() 0;
                color: vars.color('typo-primary');
            }
            display: -webkit-box;
            text-align: left;
            margin: 0;
            -webkit-box-orient: vertical;
            line-clamp: 2;
            -webkit-line-clamp: 2;
            overflow: hidden;
        }

        &main {
            display: flex;
            & > * {
                min-width: 0;
            }
        }

        &image {
            width: 256px;
            height: 140px;
            flex-shrink: 0;
            @include vars.themifyModule() {
                margin-right: vars.marginStep(2);
            }
        }

        &annotation {
            @include vars.themifyModule() {
                @include vars.text('font-s-light');
                color: vars.color('typo-primary');
            }
            display: -webkit-box;
            text-align: left;
            margin: 0;
            -webkit-box-orient: vertical;
            line-clamp: 4;
            -webkit-line-clamp: 4;
            overflow: hidden;
        }

        &categories {
            @include vars.themifyModule() {
                @include vars.text('font-s-regular');
                color: vars.color('typo-primary');
                margin-top: vars.marginStep();
            }
            text-align: left;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    a {
        @include vars.themifyModule() {
            color: vars.color('typo-link');
        }
        text-decoration: none;
    }

    :global(.highlight-search) {
        @include vars.themifyModule() {
            @include vars.text('font-s-bold');
        }
    }
}
