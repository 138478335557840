@use 'vars';

.header {
    @include vars.themifyModule() {
        background: vars.color('bg-default');
        height: vars.themed('size', 'header-height');
        z-index: vars.themed('zIndex', 'header');
        box-shadow: vars.themed('shadow', 'short');
    }
}
