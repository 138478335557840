@use 'vars';

.search-hints-panel {
    @include vars.themifyModule() {
        padding-top: vars.marginStep();
        padding-bottom: vars.marginStep(2);
    }
    border-block-start: 1px solid rgba(45, 38, 38, 0.25);
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
}

.search-hints-header {
    top: -33px;
    background-color: white;
    width: 100%;
    box-shadow: 0 5px 5px rgba(45, 38, 38, 0.25);
    position: absolute;
}

.query-hint {
    cursor: pointer;
    @include vars.themifyModule() {
	    &__hover > *,
        &:hover > *,
        &:focus > * {
            background-color: vars.color('control-additional-tab-focused');
        }
        & > div {
            line-height: 28px;
            height: 28px;
            font-size: 16px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            border-radius: 4px;
            padding: 0 8px;
        }

        &_style_main {
            padding-left: calc(
                (
                        (
                                100vw - #{vars.themed(
                                        'size',
                                        'main-search-panel-width'
                                    )}
                            ) / 2
                    ) + #{vars.marginStep() - 20px}
            );
            padding-right: (
                    100vw - vars.themed('size', 'main-search-panel-width')
                ) / 2;
        }

        &_style_search {
            padding-left: vars.marginStep(30);
            padding-right: vars.marginStep(32);

            @media (max-width: #{vars.themed('size', 'full-content-max-width') + vars.themed('padding', 'full-content-min') * 2}) {
                padding-right: vars.marginStep(12);
            }
            @media (min-width: 1900px) {
                padding-left: calc(
                    (
                            (
                                    (
                                            100% + #{vars.themed(
                                                    'padding',
                                                    'header-left'
                                                )} + #{vars.themed(
                                                    'padding',
                                                    'header-right'
                                                )}
                                        ) - #{vars.themed(
                                            'size',
                                            'full-content-max-width'
                                        )}
                                ) / 2
                        ) - #{vars.themed('padding', 'header-left')}
                );
            }
        }
    }

    &__document {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border-radius: 4px;
        line-height: 28px;
        height: 28px;
        padding: 0 8px;

        & > span {
            width: 16px;
            margin-right: 8px;
        }

        & > div {
            width: calc(100% - 24px);
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}

p.query-hint {
    color: #726d6d;
    padding: 0 8px;
    margin-top: 24px;
    margin-left: 8px;
    margin-bottom: 12px;
}
